import React, { useEffect, useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductPalette from "../components/productPalette"
import SourcingIntro from "../components/sourcingIntro"
import SourcingUsps from "../components/sourcingUsps"
import { categoryData } from '../components/categoryData'

const ProductsPage = ({ location }) => {

    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth' })

    const categories = categoryData.filter(el => el.source === "outsourced")

    const perks = [
        {
            name: 'Complex features',
            image: <StaticImage className="w-28 h-24" src='../images/icon-warranty-light.png' alt='Complex features' objectFit='contain' />,
            description:
                "We welcome critical items, rich in embroidery, patchwork, special washes, prints, and stitches. We constantly innovate to keep up to date with new design trends.",
        },
        {
            name: 'Small quantities',
            image: <StaticImage className="w-28 h-24" src='../images/icon-delivery-light.png' alt='Small quantities' objectFit='contain' />,
            description:
                "We worked our way around efficiency complexities of varied small quantity orders, enabling ourselves to cater also to the needs of smaller brands. Contact us, be sure we will meet you halfway.",
        },
        {
            name: 'Tools',
            image: <StaticImage className="w-28 h-24" src='../images/icon-returns-light.png' alt='Tools' objectFit='contain' />,
            description:
                'When it comes to clothing, it is near impossible to make decisions without experiencing the quality of a product first-hand. We provide you with a fabric catalog, with a detailed reference system together with a representative sample collection.',
        },
        {
            name: '24/7 Communication',
            image: <StaticImage className="w-28 h-24" src='../images/icon-chat-light.png' alt='24/7 Communication' objectFit='contain' />,
            description:
                "Our management team is partly located in Europe, allowing us to be present for in-person or on-call meetings at any time. And, we speak your language (Italian, English, German, Spanish, Portuguese).",
        },
    ]

    useEffect(() => {
        if (!location.state) return
        if (location.state.scrollToProducts) executeScroll()
    }, []);

    return (
        <Layout>
            <Seo title='Sara Fashionwear' />
            <SourcingIntro />
            <SourcingUsps/>
            <ProductPalette myRef={myRef} title={"Product Selection"} categories={categories} scrollToRef={executeScroll} activeNavigation={(!location.state || location.state.category === undefined) ? 'Overview' : location.state.category} />
        </Layout>
    )
}

export default ProductsPage