import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import HeroVideo from './HeroVideo'

export default function sourcingIntro() {
  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="relative pt-6 pb-8 md:pb-16">
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-left md:text-center">
              <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
                <span className="block">ABUCORP Apparel Sourcing</span>
              </h1>
              <p className="mt-3 mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl lg:max-w-3xl">
                Your one-stop service for consistent Made-in-Italy quality apparel
              </p>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="flex-1 w-full bg-gray-100" />
          </div>
          <div className="max-w-6xl mx-auto sm:px-6">
            <HeroVideo title={"Sara Fashionwear Company Video"} src={"https://player.vimeo.com/video/773977187?h=cfcfc4cee9&color=ffffff&title=0&byline=0&portrait=0responsive=true"} />
          </div>
        </div>
      </div>
    </div>
  )
}