import React from "react"
import { CheckIcon } from '@heroicons/react/outline'

const features = [
  {
    name: '24/7 customer service',
    description: 'Contact us at any time of day and we will be available. Even in person.',
  },
  { name: 'Carefully selected producers', description: 'Our sister factories are picked to guarantee transparency and consistency.' },
  {
    name: 'Top quality products',
    description: 'We promise quality apparel at made in Italy quality, no matter the source.',
  },
  { name: 'Consistent standards', description: 'We will not fail to meet the standards you require across the product range.' },
  { name: 'Competitive prices', description: 'We will find the best prices for you while never compromising on quality.' },
  { name: 'Expert quality control team', description: 'Experience, efficiency and meticulousness. These are the skills the we offer.' },
]

export default function sourcingUsps() {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl py-12 px-6 sm:pt-32 lg:grid lg:grid-cols-3 lg:gap-x-12 lg:px-8">
        <div>
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-green-700">We offer you</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900">The power of consistency</p>
          <p className="mt-6 text-base leading-7 text-gray-600">
          Our expertise in the industry primed us for a confident step of expansion, bringing our core Sara Fashionwear values along: quality,  consistency and European vision.
          </p>
        </div>
        <div className="mt-20 lg:col-span-2 lg:mt-0">
          <dl className="grid grid-cols-1 gap-12 sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-3">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                <CheckIcon className="absolute mt-1 h-6 w-6 text-green-600 text-lg" aria-hidden="true"/>
                  <p className="ml-10 text-lg font-semibold leading-8 text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-10 text-base hidden sm:block leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
